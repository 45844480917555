/* eslint-disable sonarjs/no-duplicate-string */
export const icons = [
  {
    "id": "fas fa-address-book",
    "keywords": ["contact", "directory", "répertoire"]
  },
  {
    "id": "fas fa-address-card",
    "keywords": ["contact", "identification"]
  },
  {
    "id": "fas fa-adjust",
    "keywords": ["settings", "contrast", "paramètres", "contraste"]
  },
  {
    "id": "fas fa-align-center",
    "keywords": ["text", "alignment", "texte", "alignement"]
  },
  {
    "id": "fas fa-align-justify",
    "keywords": ["text", "alignment", "texte", "alignement"]
  },
  {
    "id": "fas fa-align-left",
    "keywords": ["text", "alignment", "texte", "alignement"]
  },
  {
    "id": "fas fa-align-right",
    "keywords": ["text", "alignment", "texte", "alignement"]
  },
  {
    "id": "fas fa-allergies",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-ambulance",
    "keywords": ["emergency", "vehicle", "urgence", "véhicule"]
  },
  {
    "id": "fas fa-american-sign-language-interpreting",
    "keywords": ["communication", "sign language", "communication", "langage des signes"]
  },
  {
    "id": "fas fa-anchor",
    "keywords": ["nautical", "stability", "nautique", "stabilité"]
  },
  {
    "id": "fas fa-angle-double-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-double-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-double-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-double-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-angle-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-archive",
    "keywords": ["storage", "files", "stockage", "fichiers"]
  },
  {
    "id": "fas fa-arrow-alt-circle-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-alt-circle-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-alt-circle-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-alt-circle-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-circle-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-circle-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-circle-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-circle-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrow-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-arrows-alt",
    "keywords": ["move", "resize", "déplacer", "redimensionner"]
  },
  {
    "id": "fas fa-arrows-alt-h",
    "keywords": ["move", "resize", "déplacer", "redimensionner"]
  },
  {
    "id": "fas fa-arrows-alt-v",
    "keywords": ["move", "resize", "déplacer", "redimensionner"]
  },
  {
    "id": "fas fa-assistive-listening-systems",
    "keywords": ["hearing", "accessibility", "audition", "accessibilité"]
  },
  {
    "id": "fas fa-asterisk",
    "keywords": ["symbol", "special character", "symbole", "caractère spécial"]
  },
  {
    "id": "fas fa-at",
    "keywords": ["email", "symbol", "courriel", "symbole"]
  },
  {
    "id": "fas fa-audio-description",
    "keywords": ["accessibility", "media", "accessibilité"]
  },
  {
    "id": "fas fa-backward",
    "keywords": ["media", "rewind", "rétrograder"]
  },
  {
    "id": "fas fa-balance-scale",
    "keywords": ["justice", "law", "justice", "loi"]
  },
  {
    "id": "fas fa-ban",
    "keywords": ["prohibited", "no", "interdit", "non"]
  },
  {
    "id": "fas fa-band-aid",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-barcode",
    "keywords": ["scan", "product", "numériser", "produit"]
  },
  {
    "id": "fas fa-bars",
    "keywords": ["menu", "navigation", "menu", "navigation"]
  },
  {
    "id": "fas fa-baseball-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-basketball-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-bath",
    "keywords": ["clean", "shower", "propre", "douche"]
  },
  {
    "id": "fas fa-battery-empty",
    "keywords": ["power", "low", "puissance", "faible"]
  },
  {
    "id": "fas fa-battery-full",
    "keywords": ["power", "charged", "puissance", "chargé"]
  },
  {
    "id": "fas fa-battery-half",
    "keywords": ["power", "medium", "puissance", "moyenne"]
  },
  {
    "id": "fas fa-battery-quarter",
    "keywords": ["power", "low", "puissance", "faible"]
  },
  {
    "id": "fas fa-battery-three-quarters",
    "keywords": ["power", "high", "puissance", "élevée"]
  },
  {
    "id": "fas fa-bed",
    "keywords": ["sleep", "rest", "sommeil", "repos"]
  },
  {
    "id": "fas fa-beer",
    "keywords": ["drink", "alcohol", "boisson", "alcool"]
  },
  {
    "id": "fas fa-bell",
    "keywords": ["notification", "alert", "notification", "alerte"]
  },
  {
    "id": "fas fa-bell-slash",
    "keywords": ["notification", "mute", "notification", "muet"]
  },
  {
    "id": "fas fa-bicycle",
    "keywords": ["transportation", "bike", "transport", "vélo"]
  },
  {
    "id": "fas fa-binoculars",
    "keywords": ["search", "view", "recherche", "vue"]
  },
  {
    "id": "fas fa-birthday-cake",
    "keywords": ["celebration", "party", "célébration", "fête"]
  },
  {
    "id": "fas fa-blind",
    "keywords": ["accessibility", "vision", "accessibilité", "vision"]
  },
  {
    "id": "fas fa-bold",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-bolt",
    "keywords": ["energy", "lightning", "énergie", "éclair"]
  },
  {
    "id": "fas fa-bomb",
    "keywords": ["explosive", "danger", "explosif", "danger"]
  },
  {
    "id": "fas fa-book",
    "keywords": ["reading", "literature", "lecture", "littérature"]
  },
  {
    "id": "fas fa-bookmark",
    "keywords": ["save", "marker", "sauvegarder", "marqueur"]
  },
  {
    "id": "fas fa-bowling-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-box",
    "keywords": ["package", "container", "paquet", "conteneur"]
  },
  {
    "id": "fas fa-box-open",
    "keywords": ["package", "container", "paquet", "conteneur"]
  },
  {
    "id": "fas fa-boxes",
    "keywords": ["storage", "containers", "stockage", "conteneurs"]
  },
  {
    "id": "fas fa-braille",
    "keywords": ["accessibility", "reading", "accessibilité", "lecture"]
  },
  {
    "id": "fas fa-briefcase",
    "keywords": ["work", "business", "travail", "affaires"]
  },
  {
    "id": "fas fa-briefcase-medical",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-bug",
    "keywords": ["insect", "error", "insecte", "erreur"]
  },
  {
    "id": "fas fa-building",
    "keywords": ["office", "business", "bureau", "affaires"]
  },
  {
    "id": "fas fa-bullhorn",
    "keywords": ["announcement", "loud", "annonce", "fort"]
  },
  {
    "id": "fas fa-bullseye",
    "keywords": ["target", "goal", "cible", "objectif"]
  },
  {
    "id": "fas fa-burn",
    "keywords": ["fire", "heat", "feu", "chaleur"]
  },
  {
    "id": "fas fa-bus",
    "keywords": ["transportation", "vehicle", "transport", "véhicule"]
  },
  {
    "id": "fas fa-calculator",
    "keywords": ["math", "numbers", "mathématiques", "nombres"]
  },
  {
    "id": "fas fa-calendar",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-calendar-alt",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-calendar-check",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-calendar-minus",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-calendar-plus",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-calendar-times",
    "keywords": ["date", "schedule", "date", "horaire"]
  },
  {
    "id": "fas fa-camera",
    "keywords": ["photo", "picture", "photo", "image"]
  },
  {
    "id": "fas fa-camera-retro",
    "keywords": ["photo", "vintage", "photo", "vintage"]
  },
  {
    "id": "fas fa-capsules",
    "keywords": ["medicine", "health", "médicament", "santé"]
  },
  {
    "id": "fas fa-car",
    "keywords": ["transportation", "vehicle", "transport", "véhicule"]
  },
  {
    "id": "fas fa-caret-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-square-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-square-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-square-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-square-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-caret-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-cart-arrow-down",
    "keywords": ["shopping", "cart", "achats", "panier"]
  },
  {
    "id": "fas fa-cart-plus",
    "keywords": ["shopping", "cart", "achats", "panier"]
  },
  {
    "id": "fas fa-certificate",
    "keywords": ["award", "achievement", "prix", "réussite"]
  },
  {
    "id": "fas fa-chart-area",
    "keywords": ["graph", "data"]
  },
  {
    "id": "fas fa-chart-bar",
    "keywords": ["graph", "data"]
  },
  {
    "id": "fas fa-chart-line",
    "keywords": ["graph", "data"]
  },
  {
    "id": "fas fa-chart-pie",
    "keywords": ["graph", "data"]
  },
  {
    "id": "fas fa-check",
    "keywords": ["confirm", "correct", "confirmer", "correct"]
  },
  {
    "id": "fas fa-check-circle",
    "keywords": ["confirm", "correct", "confirmer", "correct"]
  },
  {
    "id": "fas fa-check-square",
    "keywords": ["confirm", "correct", "confirmer", "correct"]
  },
  {
    "id": "fas fa-chess",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-bishop",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-board",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-king",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-knight",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-pawn",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-queen",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chess-rook",
    "keywords": ["game", "strategy", "jeu", "stratégie"]
  },
  {
    "id": "fas fa-chevron-circle-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-circle-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-circle-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-circle-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-chevron-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-child",
    "keywords": ["person", "kid", "personne", "enfant"]
  },
  {
    "id": "fas fa-circle",
    "keywords": ["shape", "geometry", "forme", "géométrie"]
  },
  {
    "id": "fas fa-circle-notch",
    "keywords": ["shape", "loading", "forme", "chargement"]
  },
  {
    "id": "fas fa-clipboard",
    "keywords": ["note", "document", "note", "document"]
  },
  {
    "id": "fas fa-clipboard-check",
    "keywords": ["note", "document", "note", "document"]
  },
  {
    "id": "fas fa-clipboard-list",
    "keywords": ["note", "document", "note", "document"]
  },
  {
    "id": "fas fa-clock",
    "keywords": ["time", "watch", "temps", "montre"]
  },
  {
    "id": "fas fa-clone",
    "keywords": ["copy", "duplicate", "copier", "dupliquer"]
  },
  {
    "id": "fas fa-closed-captioning",
    "keywords": ["accessibility", "subtitles", "accessibilité", "sous-titres"]
  },
  {
    "id": "fas fa-cloud",
    "keywords": ["weather", "sky", "météo", "ciel"]
  },
  {
    "id": "fas fa-cloud-download-alt",
    "keywords": ["download", "cloud", "télécharger", "nuage"]
  },
  {
    "id": "fas fa-cloud-upload-alt",
    "keywords": ["upload", "cloud", "télécharger", "nuage"]
  },
  {
    "id": "fas fa-code",
    "keywords": ["programming", "development"]
  },
  {
    "id": "fas fa-code-branch",
    "keywords": ["programming", "development"]
  },
  {
    "id": "fas fa-coffee",
    "keywords": ["drink", "cafe", "boisson", "café"]
  },
  {
    "id": "fas fa-cog",
    "keywords": ["settings", "gear", "paramètres", "engrenage"]
  },
  {
    "id": "fas fa-cogs",
    "keywords": ["settings", "gear", "paramètres", "engrenage"]
  },
  {
    "id": "fas fa-columns",
    "keywords": ["layout", "design", "mise en page", "conception"]
  },
  {
    "id": "fas fa-comment",
    "keywords": ["message", "speech", "message", "discours"]
  },
  {
    "id": "fas fa-comment-alt",
    "keywords": ["message", "speech", "message", "discours"]
  },
  {
    "id": "fas fa-comment-dots",
    "keywords": ["message", "speech", "message", "discours"]
  },
  {
    "id": "fas fa-comment-slash",
    "keywords": ["message", "mute", "message", "muet"]
  },
  {
    "id": "fas fa-comments",
    "keywords": ["message", "speech", "message", "discours"]
  },
  {
    "id": "fas fa-compass",
    "keywords": ["navigation", "direction", "navigation", "direction"]
  },
  {
    "id": "fas fa-compress",
    "keywords": ["resize", "minimize", "redimensionner", "minimiser"]
  },
  {
    "id": "fas fa-copy",
    "keywords": ["duplicate", "files", "dupliquer", "fichiers"]
  },
  {
    "id": "fas fa-copyright",
    "keywords": ["legal", "rights", "juridique", "droits"]
  },
  {
    "id": "fas fa-couch",
    "keywords": ["furniture", "living room", "meubles", "salon"]
  },
  {
    "id": "fas fa-credit-card",
    "keywords": ["payment", "money", "paiement", "argent"]
  },
  {
    "id": "fas fa-crop",
    "keywords": ["image", "edit", "image", "modifier"]
  },
  {
    "id": "fas fa-crosshairs",
    "keywords": ["target", "aim", "cible", "viser"]
  },
  {
    "id": "fas fa-cube",
    "keywords": ["shape", "3D", "forme"]
  },
  {
    "id": "fas fa-cubes",
    "keywords": ["shape", "3D", "forme"]
  },
  {
    "id": "fas fa-cut",
    "keywords": ["scissors", "edit", "ciseaux", "modifier"]
  },
  {
    "id": "fas fa-database",
    "keywords": ["data", "storage", "stockage"]
  },
  {
    "id": "fas fa-deaf",
    "keywords": ["accessibility", "hearing", "accessibilité", "audition"]
  },
  {
    "id": "fas fa-desktop",
    "keywords": ["computer", "screen", "ordinateur", "écran"]
  },
  {
    "id": "fas fa-diagnoses",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-dna",
    "keywords": ["science", "genetics", "science", "génétique"]
  },
  {
    "id": "fas fa-dollar-sign",
    "keywords": ["money", "currency", "argent", "devise"]
  },
  {
    "id": "fas fa-dolly",
    "keywords": ["transport", "cart", "transport", "chariot"]
  },
  {
    "id": "fas fa-dolly-flatbed",
    "keywords": ["transport", "cart", "transport", "chariot"]
  },
  {
    "id": "fas fa-donate",
    "keywords": ["charity", "money", "charité", "argent"]
  },
  {
    "id": "fas fa-dot-circle",
    "keywords": ["selection", "radio button", "sélection"]
  },
  {
    "id": "fas fa-dove",
    "keywords": ["peace", "bird", "paix", "oiseau"]
  },
  {
    "id": "fas fa-download",
    "keywords": ["save", "arrow", "sauvegarder", "flèche"]
  },
  {
    "id": "fas fa-edit",
    "keywords": ["write", "modify", "écrire", "modifier"]
  },
  {
    "id": "fas fa-eject",
    "keywords": ["media", "remove", "supprimer"]
  },
  {
    "id": "fas fa-ellipsis-h",
    "keywords": ["menu", "more", "menu", "plus"]
  },
  {
    "id": "fas fa-ellipsis-v",
    "keywords": ["menu", "more", "menu", "plus"]
  },
  {
    "id": "fas fa-envelope",
    "keywords": ["email", "message", "courriel", "message"]
  },
  {
    "id": "fas fa-envelope-open",
    "keywords": ["email", "message", "courriel", "message"]
  },
  {
    "id": "fas fa-envelope-square",
    "keywords": ["email", "message", "courriel", "message"]
  },
  {
    "id": "fas fa-eraser",
    "keywords": ["delete", "remove", "supprimer", "enlever"]
  },
  {
    "id": "fas fa-euro-sign",
    "keywords": ["currency", "money", "devise", "argent"]
  },
  {
    "id": "fas fa-exchange-alt",
    "keywords": ["swap", "trade", "échanger", "commercer"]
  },
  {
    "id": "fas fa-exclamation",
    "keywords": ["alert", "warning", "alerte", "avertissement"]
  },
  {
    "id": "fas fa-exclamation-circle",
    "keywords": ["alert", "warning", "alerte", "avertissement"]
  },
  {
    "id": "fas fa-exclamation-triangle",
    "keywords": ["alert", "warning", "alerte", "avertissement"]
  },
  {
    "id": "fas fa-expand",
    "keywords": ["resize", "fullscreen", "redimensionner"]
  },
  {
    "id": "fas fa-expand-arrows-alt",
    "keywords": ["resize", "fullscreen", "redimensionner"]
  },
  {
    "id": "fas fa-external-link-alt",
    "keywords": ["link", "new window", "lien", "nouvelle fenêtre"]
  },
  {
    "id": "fas fa-external-link-square-alt",
    "keywords": ["link", "new window", "lien", "nouvelle fenêtre"]
  },
  {
    "id": "fas fa-eye",
    "keywords": ["view", "see", "voir"]
  },
  {
    "id": "fas fa-eye-dropper",
    "keywords": ["color", "tool", "couleur", "outil"]
  },
  {
    "id": "fas fa-eye-slash",
    "keywords": ["hide", "invisible", "cacher", "invisible"]
  },
  {
    "id": "fas fa-fast-backward",
    "keywords": ["media", "rewind"]
  },
  {
    "id": "fas fa-fast-forward",
    "keywords": ["media", "skip"]
  },
  {
    "id": "fas fa-fax",
    "keywords": ["communication", "document", "communication"]
  },
  {
    "id": "fas fa-female",
    "keywords": ["gender", "woman", "sexe", "femme"]
  },
  {
    "id": "fas fa-fighter-jet",
    "keywords": ["plane", "military", "avion", "militaire"]
  },
  {
    "id": "fas fa-file",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "fas fa-file-alt",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "fas fa-file-archive",
    "keywords": ["zip", "compress", "compresser"]
  },
  {
    "id": "fas fa-file-audio",
    "keywords": ["music", "sound", "musique"]
  },
  {
    "id": "fas fa-file-code",
    "keywords": ["development", "programming"]
  },
  {
    "id": "fas fa-file-excel",
    "keywords": ["spreadsheet", "document", "feuille de calcul"]
  },
  {
    "id": "fas fa-file-image",
    "keywords": ["photo", "document", "photo", "image"]
  },
  {
    "id": "fas fa-file-medical",
    "keywords": ["health", "document", "santé", "document"]
  },
  {
    "id": "fas fa-file-medical-alt",
    "keywords": ["health", "document", "santé", "document"]
  },
  {
    "id": "fas fa-file-pdf",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "fas fa-file-powerpoint",
    "keywords": ["presentation", "document", "présentation", "document"]
  },
  {
    "id": "fas fa-file-video",
    "keywords": ["movie", "clip", "film"]
  },
  {
    "id": "fas fa-file-word",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "fas fa-film",
    "keywords": ["movie", "cinema", "film"]
  },
  {
    "id": "fas fa-filter",
    "keywords": ["sort", "organize", "trier", "organiser"]
  },
  {
    "id": "fas fa-fire",
    "keywords": ["flame", "hot", "flamme", "chaud"]
  },
  {
    "id": "fas fa-fire-extinguisher",
    "keywords": ["safety", "fire", "sécurité"]
  },
  {
    "id": "fas fa-first-aid",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-flag",
    "keywords": ["country", "symbol", "pays", "symbole"]
  },
  {
    "id": "fas fa-flag-checkered",
    "keywords": ["race", "finish", "course"]
  },
  {
    "id": "fas fa-flask",
    "keywords": ["science", "experiment", "science", "expérience"]
  },
  {
    "id": "fas fa-folder",
    "keywords": ["directory", "files", "dossier"]
  },
  {
    "id": "fas fa-folder-open",
    "keywords": ["directory", "files", "dossier"]
  },
  {
    "id": "fas fa-font",
    "keywords": ["text", "style", "texte", "style"]
  },
  {
    "id": "fas fa-football-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-forward",
    "keywords": ["media", "skip"]
  },
  {
    "id": "fas fa-frown",
    "keywords": ["emotion", "sad", "émotion", "triste"]
  },
  {
    "id": "fas fa-futbol",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-gamepad",
    "keywords": ["gaming", "controller", "jeu"]
  },
  {
    "id": "fas fa-gavel",
    "keywords": ["judge", "law", "juge", "loi"]
  },
  {
    "id": "fas fa-gem",
    "keywords": ["jewel", "diamond", "bijou", "diamant"]
  },
  {
    "id": "fas fa-genderless",
    "keywords": ["gender", "neutral", "sexe", "neutre"]
  },
  {
    "id": "fas fa-gift",
    "keywords": ["present", "box", "cadeau", "boîte"]
  },
  {
    "id": "fas fa-glass-martini",
    "keywords": ["drink", "alcohol", "boisson", "alcool"]
  },
  {
    "id": "fas fa-globe",
    "keywords": ["world", "earth", "monde"]
  },
  {
    "id": "fas fa-golf-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-graduation-cap",
    "keywords": ["education", "school", "éducation", "école"]
  },
  {
    "id": "fas fa-h-square",
    "keywords": ["hospital", "medical", "hôpital", "médical"]
  },
  {
    "id": "fas fa-hand-holding",
    "keywords": ["support", "help", "soutien", "aide"]
  },
  {
    "id": "fas fa-hand-holding-heart",
    "keywords": ["love", "support", "amour", "soutien"]
  },
  {
    "id": "fas fa-hand-holding-usd",
    "keywords": ["money", "support", "argent", "soutien"]
  },
  {
    "id": "fas fa-hand-lizard",
    "keywords": ["gesture", "reptile", "geste"]
  },
  {
    "id": "fas fa-hand-paper",
    "keywords": ["gesture", "stop", "geste", "arrêter"]
  },
  {
    "id": "fas fa-hand-peace",
    "keywords": ["gesture", "peace", "geste", "paix"]
  },
  {
    "id": "fas fa-hand-point-down",
    "keywords": ["gesture", "point", "geste", "point"]
  },
  {
    "id": "fas fa-hand-point-left",
    "keywords": ["gesture", "point", "geste", "point"]
  },
  {
    "id": "fas fa-hand-point-right",
    "keywords": ["gesture", "point", "geste", "point"]
  },
  {
    "id": "fas fa-hand-point-up",
    "keywords": ["gesture", "point", "geste", "point"]
  },
  {
    "id": "fas fa-hand-pointer",
    "keywords": ["gesture", "click", "geste", "cliquer"]
  },
  {
    "id": "fas fa-hand-rock",
    "keywords": ["gesture", "fist", "geste"]
  },
  {
    "id": "fas fa-hand-scissors",
    "keywords": ["gesture", "cut", "geste", "couper"]
  },
  {
    "id": "fas fa-hand-spock",
    "keywords": ["gesture", "vulcan", "geste"]
  },
  {
    "id": "fas fa-hands",
    "keywords": ["gesture", "help", "geste", "aide"]
  },
  {
    "id": "fas fa-hands-helping",
    "keywords": ["gesture", "support", "geste", "soutien"]
  },
  {
    "id": "fas fa-handshake",
    "keywords": ["agreement", "deal", "accord"]
  },
  {
    "id": "fas fa-hashtag",
    "keywords": ["symbol", "trending", "symbole"]
  },
  {
    "id": "fas fa-hdd",
    "keywords": ["storage", "data", "stockage"]
  },
  {
    "id": "fas fa-heading",
    "keywords": ["text", "title", "texte", "titre"]
  },
  {
    "id": "fas fa-headphones",
    "keywords": ["audio", "music", "musique"]
  },
  {
    "id": "fas fa-heart",
    "keywords": ["love", "like", "amour", "aimer"]
  },
  {
    "id": "fas fa-heartbeat",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-history",
    "keywords": ["time", "past", "temps", "passé"]
  },
  {
    "id": "fas fa-hockey-puck",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-home",
    "keywords": ["house", "building", "maison", "bâtiment"]
  },
  {
    "id": "fas fa-hospital",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-hospital-alt",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-hospital-symbol",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-hourglass",
    "keywords": ["time", "wait", "temps", "attente", "sablier"]
  },
  {
    "id": "fas fa-hourglass-end",
    "keywords": ["time", "wait", "temps", "attente", "sablier"]
  },
  {
    "id": "fas fa-hourglass-half",
    "keywords": ["time", "wait", "temps", "attente", "sablier"]
  },
  {
    "id": "fas fa-hourglass-start",
    "keywords": ["time", "wait", "temps", "attente", "sablier"]
  },
  {
    "id": "fas fa-i-cursor",
    "keywords": ["text", "cursor", "texte"]
  },
  {
    "id": "fas fa-id-badge",
    "keywords": ["identification", "name", "identification", "nom"]
  },
  {
    "id": "fas fa-id-card",
    "keywords": ["identification", "name", "identification", "nom"]
  },
  {
    "id": "fas fa-id-card-alt",
    "keywords": ["identification", "name", "identification", "nom"]
  },
  {
    "id": "fas fa-image",
    "keywords": ["photo", "picture", "photo", "image"]
  },
  {
    "id": "fas fa-images",
    "keywords": ["photo", "picture", "photo", "image"]
  },
  {
    "id": "fas fa-inbox",
    "keywords": ["email", "messages", "courriel", "messages"]
  },
  {
    "id": "fas fa-indent",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-industry",
    "keywords": ["factory", "business", "usine", "affaires"]
  },
  {
    "id": "fas fa-info",
    "keywords": ["information", "help", "information", "aide"]
  },
  {
    "id": "fas fa-info-circle",
    "keywords": ["information", "help", "information", "aide"]
  },
  {
    "id": "fas fa-italic",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-key",
    "keywords": ["lock", "security", "verrou", "sécurité"]
  },
  {
    "id": "fas fa-keyboard",
    "keywords": ["typing", "input", "saisie"]
  },
  {
    "id": "fas fa-language",
    "keywords": ["translation", "speech", "traduction"]
  },
  {
    "id": "fas fa-laptop",
    "keywords": ["computer", "device", "ordinateur"]
  },
  {
    "id": "fas fa-leaf",
    "keywords": ["nature", "eco", "écologique"]
  },
  {
    "id": "fas fa-lemon",
    "keywords": ["fruit", "citrus", "citron"]
  },
  {
    "id": "fas fa-level-down-alt",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-level-up-alt",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-life-ring",
    "keywords": ["support", "help", "soutien", "aide"]
  },
  {
    "id": "fas fa-lightbulb",
    "keywords": ["idea", "light", "idée", "lumière"]
  },
  {
    "id": "fas fa-link",
    "keywords": ["chain", "hyperlink", "chaîne", "lien hypertexte"]
  },
  {
    "id": "fas fa-lira-sign",
    "keywords": ["currency", "money", "devise", "argent"]
  },
  {
    "id": "fas fa-list",
    "keywords": ["items", "bullet points", "articles"]
  },
  {
    "id": "fas fa-list-alt",
    "keywords": ["items", "tasks", "articles", "tâches"]
  },
  {
    "id": "fas fa-list-ol",
    "keywords": ["ordered", "numbered", "ordonné", "numéroté"]
  },
  {
    "id": "fas fa-list-ul",
    "keywords": ["unordered", "bullets", "puces"]
  },
  {
    "id": "fas fa-location-arrow",
    "keywords": ["navigation", "direction", "navigation", "direction"]
  },
  {
    "id": "fas fa-lock",
    "keywords": ["security", "protection", "sécurité", "protection"]
  },
  {
    "id": "fas fa-lock-open",
    "keywords": ["security", "unlocked", "sécurité", "déverrouillé"]
  },
  {
    "id": "fas fa-long-arrow-alt-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-long-arrow-alt-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-long-arrow-alt-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-long-arrow-alt-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "fas fa-low-vision",
    "keywords": ["accessibility", "vision", "accessibilité", "vision"]
  },
  {
    "id": "fas fa-magic",
    "keywords": ["wand", "trick", "baguette", "tour"]
  },
  {
    "id": "fas fa-magnet",
    "keywords": ["attraction", "pull", "attraction"]
  },
  {
    "id": "fas fa-male",
    "keywords": ["gender", "man", "sexe", "homme"]
  },
  {
    "id": "fas fa-map",
    "keywords": ["navigation", "location", "navigation", "emplacement"]
  },
  {
    "id": "fas fa-map-marker",
    "keywords": ["location", "pin", "emplacement"]
  },
  {
    "id": "fas fa-map-marker-alt",
    "keywords": ["location", "pin", "emplacement"]
  },
  {
    "id": "fas fa-map-pin",
    "keywords": ["location", "pin", "emplacement"]
  },
  {
    "id": "fas fa-map-signs",
    "keywords": ["directions", "navigation", "directions"]
  },
  {
    "id": "fas fa-mars",
    "keywords": ["gender", "male", "sexe", "homme"]
  },
  {
    "id": "fas fa-mars-double",
    "keywords": ["gender", "male", "sexe", "homme"]
  },
  {
    "id": "fas fa-mars-stroke",
    "keywords": ["gender", "male", "sexe", "homme"]
  },
  {
    "id": "fas fa-mars-stroke-h",
    "keywords": ["gender", "male", "sexe", "homme"]
  },
  {
    "id": "fas fa-mars-stroke-v",
    "keywords": ["gender", "male", "sexe", "homme"]
  },
  {
    "id": "fas fa-medkit",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-meh",
    "keywords": ["emotion", "neutral", "émotion", "neutre"]
  },
  {
    "id": "fas fa-mercury",
    "keywords": ["temperature", "thermometer", "température", "thermomètre"]
  },
  {
    "id": "fas fa-microchip",
    "keywords": ["technology", "computer", "technologie", "ordinateur"]
  },
  {
    "id": "fas fa-microphone",
    "keywords": ["audio", "recording", "enregistrement"]
  },
  {
    "id": "fas fa-microphone-slash",
    "keywords": ["audio", "mute", "muet"]
  },
  {
    "id": "fas fa-minus",
    "keywords": ["subtract", "remove", "supprimer"]
  },
  {
    "id": "fas fa-minus-circle",
    "keywords": ["subtract", "remove", "supprimer"]
  },
  {
    "id": "fas fa-minus-square",
    "keywords": ["subtract", "remove", "supprimer"]
  },
  {
    "id": "fas fa-mobile",
    "keywords": ["phone", "device", "téléphone"]
  },
  {
    "id": "fas fa-mobile-alt",
    "keywords": ["phone", "device", "téléphone"]
  },
  {
    "id": "fas fa-money-bill-alt",
    "keywords": ["currency", "cash", "devise"]
  },
  {
    "id": "fas fa-moon",
    "keywords": ["night", "dark", "nuit", "sombre"]
  },
  {
    "id": "fas fa-motorcycle",
    "keywords": ["vehicle", "bike", "véhicule"]
  },
  {
    "id": "fas fa-mouse-pointer",
    "keywords": ["cursor", "click", "curseur", "cliquer"]
  },
  {
    "id": "fas fa-music",
    "keywords": ["audio", "notes", "musique"]
  },
  {
    "id": "fas fa-neuter",
    "keywords": ["gender", "neutral", "sexe", "neutre"]
  },
  {
    "id": "fas fa-newspaper",
    "keywords": ["news", "article", "nouvelle"]
  },
  {
    "id": "fas fa-notes-medical",
    "keywords": ["health", "document", "santé"]
  },
  {
    "id": "fas fa-object-group",
    "keywords": ["layout", "design", "mise en page", "conception"]
  },
  {
    "id": "fas fa-object-ungroup",
    "keywords": ["layout", "design", "mise en page", "conception"]
  },
  {
    "id": "fas fa-outdent",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-paint-brush",
    "keywords": ["art", "design", "conception"]
  },
  {
    "id": "fas fa-pallet",
    "keywords": ["shipping", "logistics", "expédition"]
  },
  {
    "id": "fas fa-paper-plane",
    "keywords": ["send", "message", "envoyer"]
  },
  {
    "id": "fas fa-paperclip",
    "keywords": ["attach", "document", "attacher"]
  },
  {
    "id": "fas fa-parachute-box",
    "keywords": ["shipping", "delivery", "expédition"]
  },
  {
    "id": "fas fa-paragraph",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-paste",
    "keywords": ["copy", "document", "copier"]
  },
  {
    "id": "fas fa-pause",
    "keywords": ["media", "stop"]
  },
  {
    "id": "fas fa-pause-circle",
    "keywords": ["media", "stop"]
  },
  {
    "id": "fas fa-paw",
    "keywords": ["animal", "pet", "animal"]
  },
  {
    "id": "fas fa-pen-square",
    "keywords": ["edit", "write", "modifier", "écrire"]
  },
  {
    "id": "fas fa-pencil-alt",
    "keywords": ["edit", "write", "modifier", "écrire"]
  },
  {
    "id": "fas fa-people-carry",
    "keywords": ["support", "help", "soutien", "aide"]
  },
  {
    "id": "fas fa-percent",
    "keywords": ["discount", "sale", "réduction"]
  },
  {
    "id": "fas fa-phone",
    "keywords": ["call", "contact", "appel"]
  },
  {
    "id": "fas fa-phone-slash",
    "keywords": ["call", "mute", "appel", "muet"]
  },
  {
    "id": "fas fa-phone-square",
    "keywords": ["call", "contact", "appel"]
  },
  {
    "id": "fas fa-phone-volume",
    "keywords": ["call", "volume", "appel"]
  },
  {
    "id": "fas fa-piggy-bank",
    "keywords": ["savings", "money", "économies"]
  },
  {
    "id": "fas fa-pills",
    "keywords": ["medicine", "health", "médicament"]
  },
  {
    "id": "fas fa-plane",
    "keywords": ["flight", "travel", "vol"]
  },
  {
    "id": "fas fa-play",
    "keywords": ["media", "start"]
  },
  {
    "id": "fas fa-play-circle",
    "keywords": ["media", "start"]
  },
  {
    "id": "fas fa-plug",
    "keywords": ["power", "connect", "puissance"]
  },
  {
    "id": "fas fa-plus",
    "keywords": ["add", "increase", "ajouter"]
  },
  {
    "id": "fas fa-plus-circle",
    "keywords": ["add", "increase", "ajouter"]
  },
  {
    "id": "fas fa-plus-square",
    "keywords": ["add", "increase", "ajouter"]
  },
  {
    "id": "fas fa-podcast",
    "keywords": ["audio", "broadcast"]
  },
  {
    "id": "fas fa-poo",
    "keywords": ["funny", "toilet", "drôle"]
  },
  {
    "id": "fas fa-pound-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "fas fa-power-off",
    "keywords": ["shutdown", "power", "puissance"]
  },
  {
    "id": "fas fa-prescription-bottle",
    "keywords": ["medicine", "health", "médicament"]
  },
  {
    "id": "fas fa-prescription-bottle-alt",
    "keywords": ["medicine", "health", "médicament"]
  },
  {
    "id": "fas fa-print",
    "keywords": ["document", "paper", "document", "papier"]
  },
  {
    "id": "fas fa-procedures",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-puzzle-piece",
    "keywords": ["game", "puzzle", "jeu"]
  },
  {
    "id": "fas fa-qrcode",
    "keywords": ["scan", "code", "numériser"]
  },
  {
    "id": "fas fa-question",
    "keywords": ["help", "uncertain", "aide", "incertain"]
  },
  {
    "id": "fas fa-question-circle",
    "keywords": ["help", "uncertain", "aide", "incertain"]
  },
  {
    "id": "fas fa-quidditch",
    "keywords": ["game", "sports", "jeu", "sport"]
  },
  {
    "id": "fas fa-quote-left",
    "keywords": ["text", "citation", "texte", "citation"]
  },
  {
    "id": "fas fa-quote-right",
    "keywords": ["text", "citation", "texte", "citation"]
  },
  {
    "id": "fas fa-random",
    "keywords": ["shuffle", "randomize", "aléatoire"]
  },
  {
    "id": "fas fa-recycle",
    "keywords": ["reuse", "environment", "réutiliser"]
  },
  {
    "id": "fas fa-redo",
    "keywords": ["repeat", "retry", "répéter"]
  },
  {
    "id": "fas fa-redo-alt",
    "keywords": ["repeat", "retry", "répéter"]
  },
  {
    "id": "fas fa-registered",
    "keywords": ["trademark", "legal", "marque déposée"]
  },
  {
    "id": "fas fa-reply",
    "keywords": ["response", "message", "réponse"]
  },
  {
    "id": "fas fa-reply-all",
    "keywords": ["response", "message", "réponse"]
  },
  {
    "id": "fas fa-retweet",
    "keywords": ["share", "repost", "partager"]
  },
  {
    "id": "fas fa-ribbon",
    "keywords": ["awareness", "support", "conscience"]
  },
  {
    "id": "fas fa-road",
    "keywords": ["street", "path", "rue"]
  },
  {
    "id": "fas fa-rocket",
    "keywords": ["space", "launch", "espace"]
  },
  {
    "id": "fas fa-rss",
    "keywords": ["feed", "updates"]
  },
  {
    "id": "fas fa-rss-square",
    "keywords": ["feed", "updates"]
  },
  {
    "id": "fas fa-ruble-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "fas fa-rupee-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "fas fa-save",
    "keywords": ["disk", "document", "disque"]
  },
  {
    "id": "fas fa-search",
    "keywords": ["find", "magnify", "loupe", "chercher"]
  },
  {
    "id": "fas fa-search-minus",
    "keywords": ["zoom out", "magnify", "loupe", "chercher"]
  },
  {
    "id": "fas fa-search-plus",
    "keywords": ["zoom in", "magnify", "loupe", "chercher"]
  },
  {
    "id": "fas fa-seedling",
    "keywords": ["plant", "growth", "plante"]
  },
  {
    "id": "fas fa-server",
    "keywords": ["data", "storage", "stockage"]
  },
  {
    "id": "fas fa-share",
    "keywords": ["send", "social", "envoyer"]
  },
  {
    "id": "fas fa-share-alt",
    "keywords": ["send", "social", "envoyer"]
  },
  {
    "id": "fas fa-share-alt-square",
    "keywords": ["send", "social", "envoyer"]
  },
  {
    "id": "fas fa-share-square",
    "keywords": ["send", "social", "envoyer"]
  },
  {
    "id": "fas fa-shekel-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "fas fa-shield-alt",
    "keywords": ["security", "protection", "sécurité", "protection"]
  },
  {
    "id": "fas fa-ship",
    "keywords": ["boat", "transportation", "bateau"]
  },
  {
    "id": "fas fa-shipping-fast",
    "keywords": ["delivery", "logistics", "expédition"]
  },
  {
    "id": "fas fa-shopping-bag",
    "keywords": ["purchase", "bag", "achat"]
  },
  {
    "id": "fas fa-shopping-basket",
    "keywords": ["purchase", "basket", "achat"]
  },
  {
    "id": "fas fa-shopping-cart",
    "keywords": ["purchase", "cart", "achat"]
  },
  {
    "id": "fas fa-shower",
    "keywords": ["bath", "clean", "propre"]
  },
  {
    "id": "fas fa-sign",
    "keywords": ["notice", "board", "avis"]
  },
  {
    "id": "fas fa-sign-in-alt",
    "keywords": ["login", "enter", "connecter"]
  },
  {
    "id": "fas fa-sign-language",
    "keywords": ["gesture", "communication", "geste", "communication"]
  },
  {
    "id": "fas fa-sign-out-alt",
    "keywords": ["logout", "exit", "quitter"]
  },
  {
    "id": "fas fa-signal",
    "keywords": ["connection", "wifi", "connexion"]
  },
  {
    "id": "fas fa-sitemap",
    "keywords": ["structure", "website", "structure"]
  },
  {
    "id": "fas fa-sliders-h",
    "keywords": ["adjust", "settings", "ajuster", "paramètres"]
  },
  {
    "id": "fas fa-smile",
    "keywords": ["happy", "emotion", "heureux", "émotion"]
  },
  {
    "id": "fas fa-smoking",
    "keywords": ["cigarette", "tobacco", "cigarette"]
  },
  {
    "id": "fas fa-snowflake",
    "keywords": ["weather", "cold", "froid"]
  },
  {
    "id": "fas fa-sort",
    "keywords": ["order", "arrange", "ordonner"]
  },
  {
    "id": "fas fa-sort-alpha-down",
    "keywords": ["order", "alphabetical", "ordonné"]
  },
  {
    "id": "fas fa-sort-alpha-up",
    "keywords": ["order", "alphabetical", "ordonné"]
  },
  {
    "id": "fas fa-sort-amount-down",
    "keywords": ["order", "arrange", "ordonner"]
  },
  {
    "id": "fas fa-sort-amount-up",
    "keywords": ["order", "arrange", "ordonner"]
  },
  {
    "id": "fas fa-sort-down",
    "keywords": ["order", "arrange", "ordonner"]
  },
  {
    "id": "fas fa-sort-numeric-down",
    "keywords": ["order", "number", "numéro"]
  },
  {
    "id": "fas fa-sort-numeric-up",
    "keywords": ["order", "number", "numéro"]
  },
  {
    "id": "fas fa-sort-up",
    "keywords": ["order", "arrange", "ordonner"]
  },
  {
    "id": "fas fa-space-shuttle",
    "keywords": ["space", "exploration", "espace"]
  },
  {
    "id": "fas fa-spinner",
    "keywords": ["loading", "waiting", "chargement"]
  },
  {
    "id": "fas fa-square",
    "keywords": ["shape", "geometry", "forme", "géométrie"]
  },
  {
    "id": "fas fa-square-full",
    "keywords": ["shape", "geometry", "forme", "géométrie"]
  },
  {
    "id": "fas fa-star",
    "keywords": ["favorite", "rating", "favori"]
  },
  {
    "id": "fas fa-star-half",
    "keywords": ["favorite", "rating", "favori"]
  },
  {
    "id": "fas fa-step-backward",
    "keywords": ["media", "rewind"]
  },
  {
    "id": "fas fa-step-forward",
    "keywords": ["media", "skip"]
  },
  {
    "id": "fas fa-stethoscope",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-sticky-note",
    "keywords": ["note", "reminder", "rappel"]
  },
  {
    "id": "fas fa-stop",
    "keywords": ["media", "halt"]
  },
  {
    "id": "fas fa-stop-circle",
    "keywords": ["media", "halt"]
  },
  {
    "id": "fas fa-stopwatch",
    "keywords": ["time", "timer", "temps"]
  },
  {
    "id": "fas fa-street-view",
    "keywords": ["navigation", "map", "carte"]
  },
  {
    "id": "fas fa-strikethrough",
    "keywords": ["text", "formatting", "texte", "format"]
  },
  {
    "id": "fas fa-subscript",
    "keywords": ["text", "formatting", "texte"]
  },
  {
    "id": "fas fa-subway",
    "keywords": ["transportation", "train", "transport"]
  },
  {
    "id": "fas fa-suitcase",
    "keywords": ["travel", "luggage", "voyage"]
  },
  {
    "id": "fas fa-sun",
    "keywords": ["weather", "bright", "météo"]
  },
  {
    "id": "fas fa-superscript",
    "keywords": ["text", "formatting", "texte", "format"]
  },
  {
    "id": "fas fa-sync",
    "keywords": ["refresh", "reload", "rafraîchir"]
  },
  {
    "id": "fas fa-sync-alt",
    "keywords": ["refresh", "reload", "rafraîchir"]
  },
  {
    "id": "fas fa-syringe",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "fas fa-table",
    "keywords": ["grid", "data"]
  },
  {
    "id": "fas fa-table-tennis",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-tablet",
    "keywords": ["device", "screen", "écran"]
  },
  {
    "id": "fas fa-tablet-alt",
    "keywords": ["device", "screen", "écran"]
  },
  {
    "id": "fas fa-tablets",
    "keywords": ["medicine", "health", "médicament"]
  },
  {
    "id": "fas fa-tachometer-alt",
    "keywords": ["speed", "gauge", "vitesse"]
  },
  {
    "id": "fas fa-tag",
    "keywords": ["label", "price", "prix"]
  },
  {
    "id": "fas fa-tags",
    "keywords": ["label", "price", "prix"]
  },
  {
    "id": "fas fa-tape",
    "keywords": ["repair", "fix", "réparer"]
  },
  {
    "id": "fas fa-tasks",
    "keywords": ["to-do", "list", "tâches"]
  },
  {
    "id": "fas fa-taxi",
    "keywords": ["transportation", "vehicle", "transport"]
  },
  {
    "id": "fas fa-terminal",
    "keywords": ["command", "code"]
  },
  {
    "id": "fas fa-text-height",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-text-width",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-th",
    "keywords": ["grid", "layout", "mise en page"]
  },
  {
    "id": "fas fa-th-large",
    "keywords": ["grid", "layout", "mise en page"]
  },
  {
    "id": "fas fa-th-list",
    "keywords": ["list", "layout", "mise en page"]
  },
  {
    "id": "fas fa-thermometer",
    "keywords": ["temperature", "heat", "température"]
  },
  {
    "id": "fas fa-thermometer-empty",
    "keywords": ["temperature", "cold", "froid"]
  },
  {
    "id": "fas fa-thermometer-full",
    "keywords": ["temperature", "heat", "température"]
  },
  {
    "id": "fas fa-thermometer-half",
    "keywords": ["temperature", "moderate", "température"]
  },
  {
    "id": "fas fa-thermometer-quarter",
    "keywords": ["temperature", "low", "température"]
  },
  {
    "id": "fas fa-thermometer-three-quarters",
    "keywords": ["temperature", "high", "température"]
  },
  {
    "id": "fas fa-thumbs-down",
    "keywords": ["dislike", "negative", "négatif", "pouce"]
  },
  {
    "id": "fas fa-thumbs-up",
    "keywords": ["like", "positive", "aimer", "positif", "pouce"]
  },
  {
    "id": "fas fa-thumbtack",
    "keywords": ["pin", "pushpin", "épingle"]
  },
  {
    "id": "fas fa-ticket-alt",
    "keywords": ["pass", "admission", "billet"]
  },
  {
    "id": "fas fa-times",
    "keywords": ["close", "remove", "fermer", "supprimer"]
  },
  {
    "id": "fas fa-times-circle",
    "keywords": ["close", "remove", "fermer", "supprimer"]
  },
  {
    "id": "fas fa-tint",
    "keywords": ["drop", "water", "eau"]
  },
  {
    "id": "fas fa-toggle-off",
    "keywords": ["switch", "off", "éteint"]
  },
  {
    "id": "fas fa-toggle-on",
    "keywords": ["switch", "on", "allumé"]
  },
  {
    "id": "fas fa-trademark",
    "keywords": ["legal", "brand", "juridique"]
  },
  {
    "id": "fas fa-train",
    "keywords": ["transportation", "vehicle", "transport"]
  },
  {
    "id": "fas fa-transgender",
    "keywords": ["gender", "identity", "sexe", "identité"]
  },
  {
    "id": "fas fa-transgender-alt",
    "keywords": ["gender", "identity", "sexe", "identité"]
  },
  {
    "id": "fas fa-trash",
    "keywords": ["delete", "remove", "supprimer"]
  },
  {
    "id": "fas fa-trash-alt",
    "keywords": ["delete", "remove", "supprimer"]
  },
  {
    "id": "fas fa-tree",
    "keywords": ["nature", "plant", "plante"]
  },
  {
    "id": "fas fa-trophy",
    "keywords": ["award", "achievement", "récompense"]
  },
  {
    "id": "fas fa-truck",
    "keywords": ["transportation", "vehicle", "transport"]
  },
  {
    "id": "fas fa-truck-loading",
    "keywords": ["delivery", "logistics", "expédition"]
  },
  {
    "id": "fas fa-truck-moving",
    "keywords": ["delivery", "logistics", "expédition"]
  },
  {
    "id": "fas fa-tty",
    "keywords": ["communication", "phone", "communication"]
  },
  {
    "id": "fas fa-tv",
    "keywords": ["media", "screen", "écran"]
  },
  {
    "id": "fas fa-umbrella",
    "keywords": ["weather", "rain", "météo"]
  },
  {
    "id": "fas fa-underline",
    "keywords": ["text", "formatting", "texte", "mise en forme"]
  },
  {
    "id": "fas fa-undo",
    "keywords": ["reverse", "revert", "inverser"]
  },
  {
    "id": "fas fa-undo-alt",
    "keywords": ["reverse", "revert", "inverser"]
  },
  {
    "id": "fas fa-universal-access",
    "keywords": ["accessibility", "inclusivity", "accessibilité"]
  },
  {
    "id": "fas fa-university",
    "keywords": ["education", "school", "éducation", "école"]
  },
  {
    "id": "fas fa-unlink",
    "keywords": ["disconnect", "remove link", "déconnecter"]
  },
  {
    "id": "fas fa-unlock",
    "keywords": ["security", "access", "sécurité"]
  },
  {
    "id": "fas fa-unlock-alt",
    "keywords": ["security", "access", "sécurité"]
  },
  {
    "id": "fas fa-upload",
    "keywords": ["save", "arrow", "sauvegarder"]
  },
  {
    "id": "fas fa-user",
    "keywords": ["person", "profile", "profil", "utilisateur"]
  },
  {
    "id": "fas fa-user-circle",
    "keywords": ["person", "profile", "profil", "utilisateur"]
  },
  {
    "id": "fas fa-user-md",
    "keywords": ["doctor", "medical", "médecin", "docteur", "utilisateur"]
  },
  {
    "id": "fas fa-user-plus",
    "keywords": ["add", "person", "ajouter", "utilisateur"]
  },
  {
    "id": "fas fa-user-secret",
    "keywords": ["privacy", "incognito", "confidentialité", "utilisateur"]
  },
  {
    "id": "fas fa-user-times",
    "keywords": ["remove", "person", "supprimer", "utilisateur"]
  },
  {
    "id": "fas fa-users",
    "keywords": ["group", "people", "groupe", "utilisateur"]
  },
  {
    "id": "fas fa-utensil-spoon",
    "keywords": ["kitchen", "spoon", "cuillère"]
  },
  {
    "id": "fas fa-utensils",
    "keywords": ["kitchen", "cutlery", "couverts"]
  },
  {
    "id": "fas fa-venus",
    "keywords": ["gender", "female", "sexe", "femme"]
  },
  {
    "id": "fas fa-venus-double",
    "keywords": ["gender", "female", "sexe", "femme"]
  },
  {
    "id": "fas fa-venus-mars",
    "keywords": ["gender", "relationship", "relation"]
  },
  {
    "id": "fas fa-vial",
    "keywords": ["medical", "test", "médical"]
  },
  {
    "id": "fas fa-vials",
    "keywords": ["medical", "test", "médical"]
  },
  {
    "id": "fas fa-video",
    "keywords": ["media", "film"]
  },
  {
    "id": "fas fa-video-slash",
    "keywords": ["mute", "media", "muet"]
  },
  {
    "id": "fas fa-volleyball-ball",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "fas fa-volume-down",
    "keywords": ["audio", "sound", "son"]
  },
  {
    "id": "fas fa-volume-off",
    "keywords": ["mute", "sound", "muet"]
  },
  {
    "id": "fas fa-volume-up",
    "keywords": ["audio", "sound", "son"]
  },
  {
    "id": "fas fa-warehouse",
    "keywords": ["storage", "logistics", "stockage"]
  },
  {
    "id": "fas fa-weight",
    "keywords": ["health", "fitness", "santé"]
  },
  {
    "id": "fas fa-wheelchair",
    "keywords": ["accessibility", "disability", "accessibilité"]
  },
  {
    "id": "fas fa-wifi",
    "keywords": ["internet", "connection", "connexion"]
  },
  {
    "id": "fas fa-window-close",
    "keywords": ["close", "exit", "fermer"]
  },
  {
    "id": "fas fa-window-maximize",
    "keywords": ["fullscreen", "resize", "maximiser"]
  },
  {
    "id": "fas fa-window-minimize",
    "keywords": ["minimize", "resize", "minimiser"]
  },
  {
    "id": "fas fa-window-restore",
    "keywords": ["resize", "restore", "restaurer"]
  },
  {
    "id": "fas fa-wine-glass",
    "keywords": ["drink", "alcohol", "boisson", "alcool"]
  },
  {
    "id": "fas fa-won-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "fas fa-wrench",
    "keywords": ["tool", "repair", "outil", "réparer"]
  },
  {
    "id": "fas fa-x-ray",
    "keywords": ["medical", "health", "médical", "santé"]
  },
  {
    "id": "fas fa-yen-sign",
    "keywords": ["currency", "money", "devise"]
  },
  {
    "id": "far fa-address-book",
    "keywords": ["contact", "directory", "contact", "annuaire"]
  },
  {
    "id": "far fa-address-card",
    "keywords": ["contact", "identification", "contact", "identification"]
  },
  {
    "id": "far fa-arrow-alt-circle-down",
    "keywords": ["arrow", "navigation", "flèche"]
  },
  {
    "id": "far fa-arrow-alt-circle-left",
    "keywords": ["arrow", "navigation", "flèche"]
  },
  {
    "id": "far fa-arrow-alt-circle-right",
    "keywords": ["arrow", "navigation", "flèche"]
  },
  {
    "id": "far fa-arrow-alt-circle-up",
    "keywords": ["arrow", "navigation", "flèche"]
  },
  {
    "id": "far fa-bell",
    "keywords": ["notification", "alert", "notification"]
  },
  {
    "id": "far fa-bell-slash",
    "keywords": ["notification", "mute", "notification", "muet"]
  },
  {
    "id": "far fa-bookmark",
    "keywords": ["save", "marker", "sauvegarder"]
  },
  {
    "id": "far fa-building",
    "keywords": ["office", "business", "bureau", "affaires"]
  },
  {
    "id": "far fa-calendar",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-calendar-alt",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-calendar-check",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-calendar-minus",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-calendar-plus",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-calendar-times",
    "keywords": ["date", "schedule", "date", "calendrier"]
  },
  {
    "id": "far fa-caret-square-down",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "far fa-caret-square-left",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "far fa-caret-square-right",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "far fa-caret-square-up",
    "keywords": ["arrow", "navigation", "flèche", "navigation"]
  },
  {
    "id": "far fa-chart-bar",
    "keywords": ["graph", "data", "graphique"]
  },
  {
    "id": "far fa-check-circle",
    "keywords": ["confirm", "correct", "confirmer"]
  },
  {
    "id": "far fa-check-square",
    "keywords": ["confirm", "correct", "confirmer"]
  },
  {
    "id": "far fa-circle",
    "keywords": ["shape", "geometry", "forme", "géométrie"]
  },
  {
    "id": "far fa-clipboard",
    "keywords": ["note", "document"]
  },
  {
    "id": "far fa-clock",
    "keywords": ["time", "watch", "temps"]
  },
  {
    "id": "far fa-clone",
    "keywords": ["copy", "duplicate", "copier"]
  },
  {
    "id": "far fa-closed-captioning",
    "keywords": ["accessibility", "subtitles", "accessibilité"]
  },
  {
    "id": "far fa-comment",
    "keywords": ["message", "speech", "message"]
  },
  {
    "id": "far fa-comment-alt",
    "keywords": ["message", "speech", "message"]
  },
  {
    "id": "far fa-comments",
    "keywords": ["message", "speech", "message"]
  },
  {
    "id": "far fa-compass",
    "keywords": ["navigation", "direction", "navigation", "direction"]
  },
  {
    "id": "far fa-copy",
    "keywords": ["duplicate", "files", "fichiers"]
  },
  {
    "id": "far fa-copyright",
    "keywords": ["legal", "rights", "juridique"]
  },
  {
    "id": "far fa-credit-card",
    "keywords": ["payment", "money", "argent"]
  },
  {
    "id": "far fa-dot-circle",
    "keywords": ["selection", "radio button", "sélection"]
  },
  {
    "id": "far fa-edit",
    "keywords": ["write", "modify", "modifier", "écrire"]
  },
  {
    "id": "far fa-envelope",
    "keywords": ["email", "message", "courriel", "message"]
  },
  {
    "id": "far fa-envelope-open",
    "keywords": ["email", "message", "courriel", "message"]
  },
  {
    "id": "far fa-eye-slash",
    "keywords": ["hide", "invisible", "invisible"]
  },
  {
    "id": "far fa-file",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "far fa-file-alt",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "far fa-file-archive",
    "keywords": ["zip", "compress", "compresser"]
  },
  {
    "id": "far fa-file-audio",
    "keywords": ["music", "sound", "musique"]
  },
  {
    "id": "far fa-file-code",
    "keywords": ["development", "programming"]
  },
  {
    "id": "far fa-file-excel",
    "keywords": ["spreadsheet", "document", "feuille de calcul"]
  },
  {
    "id": "far fa-file-image",
    "keywords": ["photo", "document", "photo", "image"]
  },
  {
    "id": "far fa-file-pdf",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "far fa-file-powerpoint",
    "keywords": ["presentation", "document", "présentation", "document"]
  },
  {
    "id": "far fa-file-video",
    "keywords": ["movie", "clip", "film"]
  },
  {
    "id": "far fa-file-word",
    "keywords": ["document", "text", "document", "texte"]
  },
  {
    "id": "far fa-flag",
    "keywords": ["country", "symbol", "pays", "symbole"]
  },
  {
    "id": "far fa-folder",
    "keywords": ["directory", "files", "dossier"]
  },
  {
    "id": "far fa-folder-open",
    "keywords": ["directory", "files", "dossier"]
  },
  {
    "id": "far fa-frown",
    "keywords": ["emotion", "sad", "émotion", "triste"]
  },
  {
    "id": "far fa-futbol",
    "keywords": ["sports", "game", "sport", "jeu"]
  },
  {
    "id": "far fa-gem",
    "keywords": ["jewel", "diamond", "bijou", "diamant"]
  },
  {
    "id": "far fa-hand-lizard",
    "keywords": ["gesture", "reptile", "geste"]
  },
  {
    "id": "far fa-hand-paper",
    "keywords": ["gesture", "stop", "geste", "arrêter"]
  },
  {
    "id": "far fa-hand-peace",
    "keywords": ["gesture", "peace", "geste", "paix"]
  },
  {
    "id": "far fa-hand-point-down",
    "keywords": ["gesture", "point", "geste"]
  },
  {
    "id": "far fa-hand-point-left",
    "keywords": ["gesture", "point", "geste"]
  },
  {
    "id": "far fa-hand-point-right",
    "keywords": ["gesture", "point", "geste"]
  },
  {
    "id": "far fa-hand-point-up",
    "keywords": ["gesture", "point", "geste"]
  },
  {
    "id": "far fa-hand-pointer",
    "keywords": ["gesture", "click", "geste", "cliquer"]
  },
  {
    "id": "far fa-hand-rock",
    "keywords": ["gesture", "fist", "geste"]
  },
  {
    "id": "far fa-hand-scissors",
    "keywords": ["gesture", "cut", "geste", "couper"]
  },
  {
    "id": "far fa-hand-spock",
    "keywords": ["gesture", "vulcan", "geste"]
  },
  {
    "id": "far fa-handshake",
    "keywords": ["agreement", "deal", "accord"]
  },
  {
    "id": "far fa-hdd",
    "keywords": ["storage", "data", "stockage"]
  },
  {
    "id": "far fa-heart",
    "keywords": ["love", "like", "aimer"]
  },
  {
    "id": "far fa-hospital",
    "keywords": ["health", "medical", "santé", "médical"]
  },
  {
    "id": "far fa-hourglass",
    "keywords": ["time", "wait", "temps", "attente", "sablier"]
  },
  {
    "id": "far fa-id-badge",
    "keywords": ["identification", "name", "identification", "nom"]
  },
  {
    "id": "far fa-id-card",
    "keywords": ["identification", "name", "identification", "nom"]
  },
  {
    "id": "far fa-image",
    "keywords": ["photo", "picture", "photo", "image"]
  },
  {
    "id": "far fa-images",
    "keywords": ["photo", "picture", "photo", "image"]
  },
  {
    "id": "far fa-keyboard",
    "keywords": ["typing", "input", "saisie"]
  },
  {
    "id": "far fa-lemon",
    "keywords": ["fruit", "citrus", "citron"]
  },
  {
    "id": "far fa-life-ring",
    "keywords": ["support", "help", "soutien", "aide"]
  },
  {
    "id": "far fa-lightbulb",
    "keywords": ["idea", "light", "idée", "lumière"]
  },
  {
    "id": "far fa-list-alt",
    "keywords": ["items", "tasks", "articles", "tâches"]
  },
  {
    "id": "far fa-map",
    "keywords": ["navigation", "location", "navigation", "emplacement"]
  },
  {
    "id": "far fa-meh",
    "keywords": ["emotion", "neutral", "émotion", "neutre"]
  },
  {
    "id": "far fa-minus-square",
    "keywords": ["subtract", "remove", "supprimer"]
  },
  {
    "id": "far fa-money-bill-alt",
    "keywords": ["currency", "cash", "devise"]
  },
  {
    "id": "far fa-moon",
    "keywords": ["night", "dark", "nuit", "sombre"]
  },
  {
    "id": "far fa-newspaper",
    "keywords": ["news", "article", "nouvelle"]
  },
  {
    "id": "far fa-object-group",
    "keywords": ["layout", "design", "mise en page", "conception"]
  },
  {
    "id": "far fa-object-ungroup",
    "keywords": ["layout", "design", "mise en page", "conception"]
  },
  {
    "id": "far fa-paper-plane",
    "keywords": ["send", "message", "envoyer"]
  },
  {
    "id": "far fa-pause-circle",
    "keywords": ["media", "stop"]
  },
  {
    "id": "far fa-play-circle",
    "keywords": ["media", "start"]
  },
  {
    "id": "far fa-plus-square",
    "keywords": ["add", "increase", "ajouter"]
  },
  {
    "id": "far fa-question-circle",
    "keywords": ["help", "uncertain", "aide", "incertain"]
  },
  {
    "id": "far fa-registered",
    "keywords": ["trademark", "legal", "marque déposée"]
  },
  {
    "id": "far fa-save",
    "keywords": ["disk", "document", "disque", "sauvegarder", "enregistrer"]
  },
  {
    "id": "far fa-share-square",
    "keywords": ["send", "social", "envoyer"]
  },
  {
    "id": "far fa-smile",
    "keywords": ["happy", "emotion", "heureux", "émotion"]
  },
  {
    "id": "far fa-snowflake",
    "keywords": ["weather", "cold", "froid"]
  },
  {
    "id": "far fa-square",
    "keywords": ["shape", "geometry", "forme", "géométrie"]
  },
  {
    "id": "far fa-star",
    "keywords": ["favorite", "rating", "favori"]
  },
  {
    "id": "far fa-star-half",
    "keywords": ["favorite", "rating", "favori"]
  },
  {
    "id": "far fa-sticky-note",
    "keywords": ["note", "reminder", "rappel"]
  },
  {
    "id": "far fa-stop-circle",
    "keywords": ["media", "halt"]
  },
  {
    "id": "far fa-sun",
    "keywords": ["weather", "bright", "météo"]
  },
  {
    "id": "far fa-thumbs-down",
    "keywords": ["dislike", "negative", "négatif"]
  },
  {
    "id": "far fa-thumbs-up",
    "keywords": ["like", "positive", "aimer", "positif"]
  },
  {
    "id": "far fa-times-circle",
    "keywords": ["close", "remove", "fermer", "supprimer"]
  },
  {
    "id": "far fa-trash-alt",
    "keywords": ["delete", "remove", "supprimer"]
  },
  {
    "id": "far fa-user",
    "keywords": ["person", "profile", "profil", "utilisateur"]
  },
  {
    "id": "far fa-user-circle",
    "keywords": ["person", "profile", "profil", "utilisateur"]
  },
  {
    "id": "far fa-window-close",
    "keywords": ["close", "exit", "fermer"]
  },
  {
    "id": "far fa-window-maximize",
    "keywords": ["fullscreen", "resize", "maximiser"]
  },
  {
    "id": "far fa-window-minimize",
    "keywords": ["minimize", "resize", "minimiser"]
  },
  {
    "id": "far fa-window-restore",
    "keywords": ["resize", "restore", "restaurer"]
  }
]
