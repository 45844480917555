<template>
	<v-menu lazy bottom max-width="250px" right>
		<template v-slot:activator="{ on }">
			<v-btn
				:disabled="readonly"
				icon
				:small="small"
				:class="{ 'readonly-icon': readonly }"
				v-on="!readonly ? on : undefined"
			>
				<v-icon :small="small">{{ selected }}</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-layout row wrap>
				<w-flex grow @click.stop.prevent>
					<w-text-input
						v-model.trim="search"
						clearable
						:label="$t('actions.search')"
						prepend-icon="search"
						single-line
					/>
				</w-flex>
				<div class="icon-list" @scroll="onScroll">
					<v-flex v-for="icon in visibleIcons" :key="icon.id" xs3>
						<v-btn icon @click="emitInput(icon.id)">
							<v-icon>{{ icon.id }}</v-icon>
						</v-btn>
					</v-flex>
				</div>
			</v-layout>
		</v-card>
	</v-menu>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import debounce from 'debounce';
import { icons } from "@/components/Commons/Inputs/icons";

const LOAD_OFFSET = 200;

const props = defineProps({
	readonly: {
		default: false,
		required: false,
		type: Boolean
	},
	small: {
		default: false,
		required: false,
		type: Boolean
	},
	/**
	 * @model
	 */
	value: {
		default: () => '',
		required: false,
		type: String
	}
});

const emit = defineEmits(['input']);

const search = ref('');
const filteredIcons = ref([]);
const visibleIcons = ref([]);
const selected = ref(null);
const scrollThreshold = 100;

function searchIcons(icon, searchTerm) {
	if (icon.id.includes(searchTerm)) {
		return true;
	}
	return icon.keywords.some(keyword => keyword.includes(searchTerm));
}

function emitInput(iconValue) {
	emit('input', iconValue);
}

function onScroll(event) {
	const bottomReached = event.target.scrollTop + event.target.clientHeight >= (event.target.scrollHeight - LOAD_OFFSET);
	if (bottomReached) {
		loadMoreIcons();
	}
}

function loadMoreIcons() {
	const currentLength = visibleIcons.value.length;
	const moreIcons = filteredIcons.value.slice(currentLength, currentLength + scrollThreshold);
	visibleIcons.value = [...visibleIcons.value, ...moreIcons];
}

watch(search, debounce((value) => {
	if (!value) {
		filteredIcons.value = icons;
		visibleIcons.value = filteredIcons.value.slice(0, scrollThreshold);
		return;
	}
	const searchLower = value.toLowerCase();
	filteredIcons.value = icons.filter((icon) => searchIcons(icon, searchLower));
	visibleIcons.value = filteredIcons.value.slice(0, scrollThreshold);
}, 100), { immediate: true });

watch(() => props.value, (val) => {
	if (val) {
		selected.value = val;
	} else {
		emitInput('fas fa-check');
	}
}, { immediate: true });

onMounted(() => {
	filteredIcons.value = icons;
	visibleIcons.value = filteredIcons.value.slice(0, scrollThreshold); // Initially load a set of icons
});
</script>

<style scoped>
.icon-list {
	align-content: flex-start;
	display: flex;
	flex-wrap: wrap;
	height: 300px;
	overflow-y: auto;
	width: 250px;
}

.v-btn.readonly-icon {
	opacity: 1 !important;
	color: inherit !important;
	cursor: default !important;
}

.v-btn.readonly-icon .v-icon {
	color: inherit !important;
}
</style>
